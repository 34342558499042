import React from "react"
import { Link, graphql } from "gatsby"

// import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import { rhythm } from "../utils/typography"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title="All posts"
        description="Keep up with the latest news and articles from the Pluralistic Networks team."
      />
      {/* <Bio /> */}

      <div
        class="maxWidth pagePadding"
        style={{
          marginTop: "78px",
        }}
      >
        <div style={{ marginBottom: "65px", textAlign: "center" }}>
          <span class="pageTitle2">Blog</span>
        </div>
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <>
              <article key={node.fields.slug}>
                <header>
                  <h3
                  // style={{
                  //   marginBottom: rhythm(1 / 4),
                  // }}
                  >
                    <Link class="pubTitle" to={node.fields.slug}>
                      {title}
                    </Link>
                  </h3>
                  <small>{node.frontmatter.date}</small>
                </header>
                <section>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: node.frontmatter.description || node.excerpt,
                    }}
                  />
                </section>
              </article>
              <div
                style={{ marginBottom: "28px", marginTop: "28px" }}
                class="topLine"
              >
                &nbsp;
              </div>
            </>
          )
        })}
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
